import { useEffect, MutableRefObject } from 'react'

type Event = MouseEvent | TouchEvent

function useOnClickOutside(
  ref: MutableRefObject<null> | MutableRefObject<undefined>,
  handler: (event: Event) => void
): void {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current
      if (!el || el.contains((event?.target as Node) || null)) {
        return
      }
      handler(event)
    }
    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
    }
  }, [ref, handler])
}

export default useOnClickOutside
