import { AnalyticsState, ActionType } from '../context/AnalyticsContext'
import { analyticsService } from '../../services/analytics';

const AnalyticsReducer = (
  state: AnalyticsState,
  action: ActionType
): AnalyticsState => {
  const { type } = action

  switch (type) {

    case 'testEvent': {
      const newState = { ...state }
      return newState
    }

    case 'allPageTypes': {
      const allPageTypes = [ ...action.payload ];
      analyticsService.setPageTypeList(allPageTypes);
      return { ...state, allPageTypes }
    }

    case 'coverageCheck': {
      const newState = { ...state }
      analyticsService.pushCoverageCheckEvent(action.payload);
      newState.events = [action.payload, ...state.events];
      return newState;
    }

    default: {
      return state
    }
  }
}

export default AnalyticsReducer;
