import { createContext, Dispatch } from 'react'

export interface NavigationState {
  isExpanded: boolean
  currentOpenDropdown?: string
}

export interface ActionType {
  type: string
  currentDropdown?: string
}

const initialState = {
  isExpanded: false,
}

const NavigationContext = createContext<{
  state: NavigationState
  dispatch: Dispatch<ActionType>
}>({
  state: initialState,
  dispatch: () => null,
})

export default NavigationContext
