import { NavigationState, ActionType } from '../context/NavigationContext'

const NavigationReducer = (
  state: NavigationState,
  action: ActionType
): NavigationState => {
  const { type } = action

  switch (type) {
    case 'closeMobileNav': {
      const newState = { ...state }

      newState.isExpanded = false
      newState.currentOpenDropdown = undefined
      return newState
    }

    case 'toggleMobileNav': {
      const newState = { ...state }

      newState.isExpanded = !newState.isExpanded
      if (!newState.isExpanded) {
        newState.currentOpenDropdown = undefined
      }
      return newState
    }

    case 'closeDropdownNav': {
      const newState = { ...state }

      newState.currentOpenDropdown = undefined
      return newState
    }

    case 'toggleDropdownNav': {
      const newState = { ...state }

      newState.currentOpenDropdown =
        newState.currentOpenDropdown === action.currentDropdown
          ? undefined
          : action.currentDropdown
      return newState
    }

    default: {
      return state
    }
  }
}

export default NavigationReducer
