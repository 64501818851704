import React, { useReducer } from 'react'
import { analyticsService } from './services/analytics'
import './src/assets/fonts/icomoon'
import './src/assets/fonts/human'
import './src/styles/globals.css'
import { useAnalytics, useNavigation } from './src/hooks'
import { AnalyticsContext, NavigationContext } from './src/context'

let formattedPageTypeList = []

const TEMPLATE_PAGE_DOMAINS = ['legal', 'health-and-aging', 'cart']

async function fetchPageTypeList() {
  return await analyticsService.getPageTypeList()
}

function removeTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, str.length - 1) : str
}

function formatPageTypeListSlugs(pageTypeList) {
  let node = {}
  let slug = ''
  return pageTypeList.map(pageType => {
    node = pageType.node
    slug = node.slug
    return {
      ...node,
      slug: removeTrailingSlash(slug),
    }
  })
}

function findPageTypeMatch(path) {
  let matches = []

  // take page path, split the string into an array based on "/"
  // then, filters out empty and/or whitespace entries
  // the result is an array of length 1 or 2, containing the domain and subdomain
  let splitPagePath = path.split('/').filter(s => s.trim().length > 0)

  let domain = splitPagePath[0]
  let subdomain = splitPagePath[1] || null

  // the home page has no domain or page path and thus needs this set manually
  if (splitPagePath.length === 0 && (path === '' || path === '/')) {
    domain = 'home'
  }

  // conditional specific to template subdomain pages
  if (subdomain && TEMPLATE_PAGE_DOMAINS.includes(domain)) {
    // first we check to see if this is a subpage that's in contentful
    formattedPageTypeList.forEach(pageType => {
      if (
        path.includes(pageType.slug) &&
        domain === pageType.gtmPageType.toLowerCase()
      ) {
        matches.push(pageType)
      }
    })
    // second, if there are no matches from the forEach above, then we're actually dealing with a template-generated page!
    if (!matches.length) {
      matches.push({
        seoTitle: document.title, // no pageType.seoTitle here, so we capture document.title instead
        gtmPageType:
          domain === 'health-and-aging'
            ? 'Health and Aging'
            : domain === 'legal'
            ? 'Legal'
            : domain === 'cart'
            ? 'Cart'
            : '',
      })
    }
  } else {
    formattedPageTypeList.forEach(pageType => {
      if (pageType.slug === path) {
        matches.push(pageType)
      } else if (pageType.slug === '' && domain === 'home') {
        // this condition is a special case to cover the home page
        matches.push(pageType)
      }
    })
  }
  return matches
}

// @ts-ignore
export async function onRouteUpdate({ location, prevLocation }) {
  let path = removeTrailingSlash(location.pathname)

  await analyticsService.pageTypeListReady().then(async () => {
    if (!formattedPageTypeList || !formattedPageTypeList.length) {
      await fetchPageTypeList().then(async res => {
        formattedPageTypeList = formatPageTypeListSlugs(res)
        let pageTypeMatches = findPageTypeMatch(path)
        if (pageTypeMatches.length) {
          // TODO: deal with potentially multiple matches, for now just assuming match[0]
          await analyticsService.awaitTrackingEvent(
            location,
            pageTypeMatches[0]
          )
        }
      })
    } else {
      let pageTypeMatches = findPageTypeMatch(path)
      if (pageTypeMatches.length) {
        // TODO: deal with potentially multiple matches, for now just assuming match[0]
        await analyticsService.awaitTrackingEvent(location, pageTypeMatches[0])
      }
    }
  })
}

const ProviderWrapper = ({ element }) => {
  const [navigationState, navigationDispatch] = useReducer(useNavigation, {
    isExpanded: false,
  })

  const [analyticsState, analyticsDispatch] = useReducer(useAnalytics, {
    events: [],
    allPageTypes: [],
  })

  return (
    <AnalyticsContext.Provider
      value={{ state: analyticsState, dispatch: analyticsDispatch }}
    >
      <NavigationContext.Provider
        value={{ state: navigationState, dispatch: navigationDispatch }}
      >
        {element}
      </NavigationContext.Provider>
    </AnalyticsContext.Provider>
  )
}

export function wrapRootElement({ element, props }) {
  return <ProviderWrapper element={element} props={props} />
}

export const onPreRouteUpdate = () => {
  document.body.scrollTop = 0
}
