import { createContext, Dispatch } from 'react'

export interface AnalyticsRoutingEvent {
  event: string,
  page_url: string,
  page_path: string,
  page_title: string,
  page_type: string,
  page_template: string
}

export interface AnalyticsState {
  events: AnalyticsRoutingEvent[],
  allPageTypes: any[] // TODO: any
}

export interface ActionType {
  type: string
  payload?: any // TODO: any
}

const initialState = {
  events: [],
  allPageTypes: []
}

const AnalyticsContext = createContext<{
  state: AnalyticsState
  dispatch: Dispatch<ActionType>
}>({
  state: initialState,
  dispatch: () => null,
})

export default AnalyticsContext;
