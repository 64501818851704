function AnalyticsService() {

  this.setPageTypeList = (data: any) => {
    this.pageTypeList = data;
  }

  this.getPageTypeList = async () => {
    return await this.pageTypeList;
  }

  this.pageTypeListReady = async () => {
    let n = 0
    while (!this.pageTypeList) {
      // applying an incremental timeout delay so this method won't waste cycles
      // this scales up each consecutive delay by a factor of two
      // 100 * 2^n => 100ms, 200ms, 400ms, 800ms, 1600ms etc
      await new Promise(resolve => setTimeout(resolve, 100 * (2 ** n++)));
    }
  }

  this.dataLayerReady = async () => {
    await dataLayer;
    // TODO: might need to add a second await, like:
    //       await dataLayer.find(e => e.event === "gtm.dom")
  }

  this.pushPageViewEvent = async (match) => {
    const e = {
      event: 'CustomPageViewWithPageType',
      page_url: location.href,
      page_path: location.pathname,
      page_title: match.seoTitle || document.title || '',
      page_type: match.gtmPageType,
    }
    await dataLayer.push(e);
  }

  this.pushCoverageCheckEvent = async (e) => {
    await dataLayer.push(e);
  }

  // TODO: assuming 1 match for now
  this.awaitTrackingEvent = async (location: any, match: any) => {
    await this.dataLayerReady().then(async () => {
      if (!match.seoTitle && document.title === "") {
        // TODO: come up with a less dirty workaround
        // gatsby's onRouteUpdate event fires before the DOM is loaded, and
        // document.title isn't accessible until the DOM loads
        setTimeout(this.pushPageViewEvent, 1000, match)
      } else {
        this.pushPageViewEvent(match)
      }
    })
  }
}

// @ts-ignore
const analyticsService = new AnalyticsService();

// we're just exporting a singleton to share this service between
// the gatsby-xxx files and the react app
export default analyticsService
